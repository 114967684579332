import React from "react"
import { Container, Row, Col, Button } from "reactstrap"
import Layout from "../containers/Layout"
import SEO from "../components/seo"
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"
import ContactLogo from '../images/contact-us/placeholder.png'
import EmailLogo from '../images/contact-us/message.png'

const Action = (props) => {
    const {
        actionType,
        imageSource
    } = props
    return (
        <div className={'contact-action'}>
            <h3 className={'action-type'}>{actionType}</h3>
            {
                imageSource &&
                <img src={imageSource} alt="" className={'action-logo'} />
            }
        </div>
    )
}
const Contact = (props) => {
    const {
        actionType,
        imageSource,
        rtl
    } = props
    return (
        <div className={`contact-section ${rtl ? 'contact-us-rtl' : 'contact-us-ltr'}`}>
            <Row>
                <Col md={4}>
                    <Action
                        actionType={actionType}
                        imageSource={imageSource}
                    />
                </Col>
                <Col md={8} className={'flex-column-center'}>
                   
                    <p><FormattedMessage id={'contact_address'} /> </p>
                    <p><FormattedMessage id={'contact_address_1'} /> </p>
                    <p><FormattedMessage id={'contact_address_2'} /> </p>
                    <p className="contact-numbers"><FormattedMessage id={'central_contact'} /><span>00 965 9222 2157</span> <span> 00 965 2220 8310</span> </p>
                </Col>
            </Row>
        </div>
    )
}
const Email = (props) => {
    const {
        actionType,
        imageSource,
        rtl
    } = props
    return (
        <div className={`contact-section ${rtl ? 'contact-us-rtl' : 'contact-us-ltr'}`}>
            <Row>
                <Col md={4}>
                    <Action
                        actionType={actionType}
                        imageSource={imageSource}
                    />
                </Col>
                <Col md={8} className={'flex-column-center'}>
                    <p>gcceportal@emisk.org</p>
                </Col>
            </Row>
        </div>
    )
}
const DirectContact = (props) => {
    const {
        actionType,
        rtl
    } = props
    return (
        <div className={`contact-section ${rtl ? 'contact-us-rtl' : 'contact-us-ltr'}`}>
            <Row>
                <Col md={4}><Action actionType={actionType} /></Col>
                <Col md={8}></Col>
            </Row>
            <Row className={'form-item'}>
                <Col md={4} className={'flex-column-center'}><p className={'form-item-name'}><FormattedMessage id={'contact_name'} /></p></Col>
                <Col md={8}><input type="text" className={'form-item-input'} /></Col>
            </Row>
            <Row className={'form-item'}>
                <Col md={4} className={'flex-column-center'}><p className={'form-item-name'}><FormattedMessage id={'contact_email'} /></p></Col>
                <Col md={8}><input type="text" className={'form-item-input'} /></Col>
            </Row>
            <Row className={'form-item'}>
                <Col md={4} className={'flex-column-center'}><p className={'form-item-name'}><FormattedMessage id={'contact_message_title'} /></p></Col>
                <Col md={8}><input type="text" className={'form-item-input'} /></Col>
            </Row>
            <Row className={'form-item'}>
                <Col md={4} className={'flex-column-center'}><p className={'form-item-name'}><FormattedMessage id={'contact_message'} /></p></Col>
                <Col md={8}>
                    <textarea name="message" id="" cols="30" rows="10" className={'form-item-input text-area'}></textarea>
                </Col>
            </Row>
            <Row className={'form-item'}>
                <Col md={8}></Col>
                <Col md={4} className={'flex-row-left'}>
                    <Button className={'contact-section-submit-button'}>
                        <FormattedMessage id={'send'} />
                    </Button>
                </Col>
            </Row>
        </div>
    )
}
const ContactUs = (props) => {
    const rtl = props.intl.locale === "ar"
    return (
        <Layout>
            <SEO title="Agreements" />
            <div className={'contact-us-wrapper'}>
                <Container>
                     <Contact
                        actionType={<FormattedMessage id={'contact_us'} />}
                        imageSource={ContactLogo}
                        rtl={rtl}
                    /> 
                    <Email
                        actionType={<FormattedMessage id={'email'} />}
                        imageSource={EmailLogo}
                        rtl={rtl}
                    />
                    <DirectContact
                        actionType={<FormattedMessage id={'direct_contact'} />}
                        rtl={rtl}
                    />
                </Container>
            </div>
        </Layout >
    )
}
export default injectIntl(ContactUs)
